document.addEventListener('turbolinks:load', (event) => {
    let menuButton = document.getElementById('hamburger')
    let burgerNavDiv = document.getElementById("burger-nav-items")
    let navigationLinks = document.querySelectorAll("no-link-styles")

    function toggleBurgerMenu() {
        if (burgerNavDiv.style.display == "none") {
            menuButton.classList.add("is-active")
            burgerNavDiv.style.display = "flex"
        } else {
            menuButton.classList.remove("is-active")
            burgerNavDiv.style.display = "none"
        }
    }

    function fixNavLinks() {
        menuButton.classList.remove("is-active")
    }

    menuButton.addEventListener('click', toggleBurgerMenu)
    navigationLinks.forEach(link => {
        link.addEventListener('click', fixNavLinks)
    })

})
